/* Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0*/

/* FONTS*/

@import url('https://fonts.googleapis.com/css?family=Work+Sans&display=swap');

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

@import url('https://fonts.googleapis.com/css?family=Inconsolata&display=swap');

h2,
h3,
h4,
h5 {
  line-height: initial
}

#root {
  position: relative;
  min-width: 100% !important;
  min-height: 100% !important;
  display: flex;
  flex-direction: column;
  background-color: #f6f9ff !important;
  background-size: cover !important;
}


.ui.header {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  font-style: normal !important;
  color: #00177f !important;
}

h2 {
  font-family: 'Open Sans', sans-serif !important;
  line-height: 1.25 !important;
  font-weight: 600 !important;
  font-style: 600;
  color: #00177f;
  font-size: 1.875em;
  transition-duration: 0.4s;
}

h3 {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 650 !important;
  line-height: 1.25;
  font-style: normal;
  color: #00177f;
}

h4 {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 1000 !important;
  line-height: 1.5em;
  letter-spacing: 0.25px;
  font-style: regular;
  color: #1e2783;
  font-size: 1em;
}

p {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: light;
  line-height: 1.5em;
  font-style: regular;
  color: #1e2783;
  font-size: 1em;
}

body {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: light !important;
  line-height: 1.5em;
  letter-spacing: 0.25px;
  font-style: regular;
  color: #1e2783;
  font-size: 1em;
}

.ui.grid {
  padding-left: 180px;
}

.grid.finchcard, .container.finchcard {
  padding-left: 55px;
  padding-bottom: 10px;
  padding-top: 30px;
  background-color: white;
  padding-right: 0px;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08)
}

.grid.authentication, .container.authentication {
  padding-left: 55px;
  padding-bottom: 40px;
  padding-top: 40px;
  background-color: white;
  padding-right: 60px;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08)
}


.container.dashboard, .grid.dashboard {
  padding-left: 70px;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-right: 40px;
  background-color: white;
  padding-right: 60px;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08)
}

.grid.tutorial {
  padding-left: 70px;
  padding-bottom: 60px;
  padding-top: 20px;
  padding-right: 40px;
  background-color: white;
  padding-right: 60px;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 40px;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08)
}

.container.banner {
  padding-left: 70px;
  padding-bottom: 30px;
  padding-right: 60px;
  padding-top: 30px;
  background-color: #E9A4B1;
  border-radius: 10px;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08)
}

.container.swagger {
  padding-left: 25px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-top: 0px;
  background-color: white;
  border-radius: 10px;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08)
}

.ui.checkbox {
  color: #1e2783 !important;
  size: 3em !important;
  transform: scale(1.25);
  border-radius: 40px !important;
  position: relative;
  padding: 5px;
}

.ui.checkbox input:checked ~ .box:after, .ui.checkbox input:checked ~ label:after {
  color: #1e2783;
}

.ui.checkbox label:hover {
  color: #1e2783 !important;
}
.ui.checkbox input:checked:focus  {
  background: #FFFFFF;
  border-color: #96C8DA;
  color: #1e2783 !important;
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
  background-color: rgba(27, 68, 133, 0.1);
}

@keyframes App-logo-spin {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.08);
  }
}

/* fix semantic-ui quirks */
.ui.negative.floating.message,
.ui.attached.negative.floating.message {
  box-shadow:
    0px 0px 0px 1px #1b4485 inset,
    0px 2px 4px 0px rgba(27, 68, 133, 0.7),
    0px 2px 10px 0px rgba(27, 68, 133, 0.85)
}

.ui.input--code__readonly {
  width: 100%;
}

.ui.input--code__readonly>input {
  background-color: rgba(184, 200, 216, 0.30);
  background: rgba(184, 200, 216, 0.30);
  font-family: monospace, monospace;
  width: 100%;
  border: none;
  padding: 15px 15px;
  color: #1e2783;
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 10px;
}

.ui.input--code__readonly>input:focus {
  background-color: rgba(200, 216, 231, 0.3);

}

.cell__header {
  font-weight: 900;
  font-family: 'Open Sans', sans-serif;
}

.ui.table.table__api-client {
  margin-bottom: 2em;
  /* box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05); */
  border-radius: 10px;
  padding: 5px;
}

.ui.negative.button {
  background-color: #fff !important;
  color: #1e2783 !important;
  border: 1px solid #1e2783 !important;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.ui.negative.button:hover {
  background-color: #fff !important;
  border: 2px solid #DD5971 !important;
  color: #DD5971;
}

.tabs>div {
  padding: 1em
}


/* fix semantic-ui quirks */
.ui.negative.floating.message,
.ui.attached.negative.floating.message {
  box-shadow:
    0px 0px 0px 1px #2562ca inset,
    0px 2px 4px 0px rgba(27, 68, 133, 0.7),
    0px 2px 10px 0px rgba(27, 68, 133, 0.85)
}

/* Side bar */


.ui.vertical.menu {
  display: block;
  flex-direction: column;
  background: #0f163a !important;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
  border-color: #0f163a !important;
  padding-top: 2.5rem;
  padding-left: 1rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
  position: fixed;
  z-index: 1200;
  overflow: auto;
  /* Scrollable contents if viewport is shorter than content. */
  flex: 1 0 auto;
  height: 100vh;
  width: 240px;
  font-family: 'Open Sans', sans-serif;
  margin: 0 0 .2rem;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.ui.vertical.menu .menu .item {
  background: none;
  padding: 0.5em 1.33333333em;
  font-size: 16px;
  font-weight: 400;
  color: #fefefe !important;
  padding: 13px 5px 13px 16px;
  font-family: 'Open Sans', sans-serif !important;
  border-radius: 7px;
  overflow: hidden;
}

.ui.vertical.menu .menu .item:hover {
  color: #fff !important;
}
.ui.vertical.menu .menu .item:active {
  color: #ffffff;
}
.ui.vertical.menu .menu .header {
  margin: 1.5rem 0 0.65rem;
  padding: 0.5em 1.33333333em;
  color: #b2b2b2;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
}


/* Buttons on Developer portal main page*/

.ui.positive.button {
  background-color: #043ec4 !important;
  color: #fff;
  border-radius: 20px;
  Font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border: 1px solid #043ec4;
}

.ui.positive.button:hover {
  background-color: #043ec4;
  color: #DD5971 !important;
}

/* Buttons on APIs Swagger page*/


.ui.button {
  background-color: #043ec4 !important;
  border: 1px solid #043ec4;
  color: #fff;
  border-radius: 20px;
  Font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  padding: 0.75em;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

}

.ui.button:hover {
  background-color: #043ec4;
  color: #DD5971 !important;
}

/* Main Button in Swagger ie. "Try it out"*/
.swagger-ui button {
  background-color: #2562ca !important;
  border: 1px solid #2562ca !important;
  color: #ffffff !important;
  box-shadow: none !important;
  border-radius: 20px !important;
  Font-family: 'Open Sans', sans-serif !important;
  font-weight: 800 !important;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin: 15px;
}

.swagger-ui .authorization__btn.locked {
  margin-Right: 15px;
  padding: 0.3em;
  padding-left: 1em;
  padding-right: 1em;
  border: none !important;
  background-color: transparent !important;
}
.swagger-ui .authorization__btn.unlocked {
  margin-Right: 15px;
  padding: 0.3em;
  padding-left: 1em;
  padding-right: 1em;
  border: none !important;
  background-color: transparent !important;
}

.swagger-ui .opblock .opblock-section-header{
  border-radius: 10px;
  padding-left: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #ffffff;
  box-shadow: none;
}
.swagger-ui .expand-methods, .swagger-ui .expand-operation {
  margin-Right: 0px;
  color: #1e2783 !important;
  padding: 0.1em;
  padding-left: 0.6em;
  padding-right: 0.6em;
  border: none !important;
  background-color: #fff !important;
}

.swagger-ui .expand-methods, .swagger-ui .expand-operation:hover {
  margin-Right: 0px;
  color: #1e2783 !important;
  padding: 0.1em;
  padding-left: 0.6em;
  padding-right: 0.6em;
  border: none !important;
  background-color: #fff !important;
}

.swagger-ui button:hover {
  background-color: #3373e0 !important;
  border: 1px solid #3373e0 !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  color: #ffffff !important;
  border-radius: 20px !important;

}

/* Button with selection in Swagger*/
.swagger-ui select {
  background-color: #f6f9ff !important;
  border: 1px solid #1e2783 !important;
  color: #092d6b !important;
  border-radius: 20px !important;
  Font-family: 'Open Sans', sans-serif !important;
  font-weight: 800 !important;
  padding: 0.75em;
  padding-left: 1.25em;
  padding-right: 1.25em;
  margin: 10px;
  box-shadow: none;
}

.swagger-ui select:hover {
  background-color: #f6f9ff !important;
  border: 1px solid #2562ca !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  color: #2562ca !important;
  border-radius: 20px !important;
}

/* Background "Post" in Swagger*/
.swagger-ui .opblock.opblock-post {
  background: #e8eaff !important;
  border-radius: 10px !important;
  margin-top: 20px;
  border: none;
  box-shadow: none;
}
/* Background "DELETE" in Swagger*/
.swagger-ui .opblock.opblock-delete {
  background: #f8e0e2 !important;
  border-radius: 10px !important;
  margin-top: 20px;
  border: none;
  box-shadow: none;
}

/* Button "POST" in Swagger*/
.swagger-ui .opblock.opblock-post .opblock-summary-method {
  background-color: #1e2783 !important;
  color: #fff !important;
  Font-family: 'Open Sans', sans-serif !important;
  border-radius: 10px !important;
  font-weight: 400 !important;
  padding: 10px !important;
  margin: 5px;
  margin-left: 5px;
  border: #1e2783;
}
/* Button "DELETE" in Swagger*/
.swagger-ui .opblock.opblock-delete .opblock-summary-method {
  background-color: #d1464d !important;
  color: #fff !important;
  Font-family: 'Open Sans', sans-serif !important;
  border-radius: 10px !important;
  font-weight: 400 !important;
  padding: 10px !important;
  margin: 5px;
  margin-left: 5px;
  border: #d1464d;
}

/* body "Post" in Swagger*/

.swagger-ui .opblock.opblock-post .opblock-summary {
  border: none;
  border-radius: 10px !important;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

/* Background "GET" in Swagger*/

.swagger-ui .opblock.opblock-get {
  background: #e8f0ff !important;
  border-radius: 10px !important;
  margin-top: 20px;
  border: none;
  box-shadow: none;
}

/* Button "GET" in Swagger*/

.swagger-ui .opblock.opblock-get .opblock-summary-method {
  background: #2562ca !important;
  color: #fff !important;
  Font-family: 'Open Sans', sans-serif !important;
  border-radius: 10px !important;
  font-weight: 400 !important;
  padding: 10px !important;
  margin: 5px;
  margin-left: 0px;
  border: #1e2783;
}
/* body "Post" in Swagger*/

.swagger-ui .opblock.opblock-get .opblock-summary {
  border: none;
  border-radius: 10px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

/*  MODELS */
.swagger-ui section.models {
  border: none;
  box-shadow: none; 
  background: #fff !important;
  border-radius: 10px;
}

.swagger-ui section.models .model-container {
  background: #e9f0ff !important;
  border: none;
  border-radius: 10px;
  margin-left: 0px !important;
  padding-left: 10px;
  margin-right: 0px !important;
}

.swagger-ui .model {
  font-size: 13px;
  font-weight: 300;
  font-weight: 600;
  color: #3b4151;
}


/*  SCHEME  */
.swagger-ui .scheme-container {
  margin-top: 30px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  background: #e9f0ff !important;
  border-radius: 10px !important;
  box-shadow: none;
}

.swagger-ui .scheme-container .schemes>label {
  Font-family: 'Open Sans', sans-serif !important;
  Font-size: 13px !important;
  padding-top: 20px !important;
  color: #1e2783 !important;
}


/* Description of the summary block*/
.swagger-ui .opblock .opblock-summary-description {
  Font-family: 'Open Sans', sans-serif !important;
  color: #092d6b !important;
}

/* Title of the summary block*/
.swagger-ui .parameter__name {
  Font-family: 'Open Sans', sans-serif !important;
  color: #092d6b !important;
}

.swagger-ui .opblock .opblock-section-header h4 {
  Font-family: 'Open Sans', sans-serif !important;
  color: #092d6b !important;
}

.swagger-ui a {
  Font-family: 'Open Sans', sans-serif !important;
  color: #092d6b !important;
}

.swagger-ui {
  font-family: 'Open Sans', sans-serif !important;
  color: #092d6b !important;
}

.swagger-ui section.models h1 {
  font-family: 'Open Sans', sans-serif !important;
  color: #092d6b !important;
}

.swagger-ui section.models h2 {
  font-family: 'Open Sans', sans-serif !important;
  color: #092d6b !important;
}

.swagger-ui section.models h3 {
  font-family: 'Open Sans', sans-serif !important;
  color: #092d6b !important;
}

.swagger-ui section.models h4 {
  font-family: 'Open Sans', sans-serif !important;
  color: #092d6b !important;
}

.swagger-ui .model-title {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  color: #092d6b !important;
  font-size: 14px;
}



.swagger-ui .btn.authorize svg {
  fill: #092d6b;
}

.swagger-ui .opblock-body pre {
  background: #28384d !important;
  border-radius: 10px !important;
  font-family: Consolas,"Liberation Mono",Courier,monospace !important;
  font-size: 12px !important;
  display: block;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  font-weight: 400;
}


.swagger-ui .response-col_description__inner div.markdown {
  background: #f6f9ff !important;
  border-radius: 10px !important;
  color: #28384d !important;
  font-family: Consolas,"Liberation Mono",Courier,monospace !important;
  font-size: 12px !important;
  display: block;
  margin-bottom: 1.5rem;
  padding: 1rem;
  padding-left: 20px;
  font-weight: 400;
  font-style: normal;
}
.swagger-ui .response-col_description__inner div.markdown p {
  color: #28384d !important;
  font-size: 12px !important;
  font-family: Consolas,"Liberation Mono",Courier,monospace !important;
  font-weight: 400;
}

.swagger-ui .markdown p {
  color: #092d6b !important;
  font-size: 12px !important;
  font-family: 'Open Sans' serif !important;
  font-weight: 400;
}



.ui.active.label {
  background-color: #ffffff;
  border: 2px solid #DD5971;
  padding: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #DD5971;
  border-radius: 20px;
  margin-right: 0px;
}
.swagger-ui .markdown code, .swagger-ui .renderedMarkdown code {
  font-size: 12px;
  padding: 5px 7px;
  border-radius: 4px;
  background: rgba(0,0,0,.05);
  font-family: Consolas,"Liberation Mono",Courier,monospace !important;
  font-weight: 500;
  color: #00177f;
}
.swagger-ui table.model tbody tr td{

  margin-top: 20px !important;
  padding-top: 10px !important;
  font-size: 13px;
  font-family: Consolas,"Liberation Mono",Courier,monospace !important;
  font-weight: 600;
  color: #00177f !important;
}

.swagger-ui table thead tr td, .swagger-ui table thead tr th {
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #666b72;
  font-family: 'Open Sans', sans-serif !important;
  color: #092d6b !important;
  padding-left: 15px !important;
  margin-bottom: 20px !important;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
  font-family: 'Open Sans', sans-serif !important;
  color: #092d6b !important;
  font-size: 13px;
}

.swagger-ui table tbody tr td{
  color: #092d6b !important;
  font-size: 13px;
  padding-left: 15px !important;
}
.swagger-ui .dialog-ux .modal-ux-content h4 {
  color: #092d6b !important;
  font-family: 'Open Sans', sans-serif !important;
}


